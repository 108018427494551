import {shapeOf} from "@vimana/vimana-types"
import {validateInterval} from "../Interval"

/**
 * A base interface for items which occur in a timeline.
 */


/**
 * Validate an item in a timeline.
 */
export const validateTimelineItem = shapeOf({
  interval: validateInterval
})
