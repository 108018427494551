import {validateInterval} from "@vimana/lib-timeline"

import {
  shapeOf,
  arrayOf,
  optional,
  validateString,
  validateDurationMS
} from "@vimana/vimana-types"
import {validateAgenda} from "./Agenda"

/**
 * Schedules determine event and classification time information for a device,
 * group of devices or an entire plant.
 */


/**
 * Validates a Schedule ID.
 */
export function validateScheduleID(input) {
  return validateString(input)
}

/**
 * Validates a Schedule Name.
 */
export function validateScheduleName(input) {
  return validateString(input)
}

/**
 * Validates a Schedule.
 */
export const validateSchedule = shapeOf({
  id: optional(validateScheduleID),
  name: validateScheduleName,
  interval: validateInterval,
  workdayOffset: validateDurationMS,
  agendas: arrayOf(validateAgenda)
})

/**
 * Get the plant level agenda within a schedule.
 * @throws {Error} if no plant level agenda is found.
 */
export function getPlantAgenda(schedule) {
  for (const agenda of schedule.agendas) {
    if (agenda.devices.length === 0) {
      return agenda
    }
  }
  throw new Error("Invalid schedule, no plant level agenda specified")
}

/**
 * Get the agenda for a specific device within a schedule.
 * Returns the plant level agenda if no agenda exists for the device.
 */
export function getDeviceAgenda(schedule, device) {
  for (const agenda of schedule.agendas) {
    if (agenda.devices.includes(device)) {
      return agenda
    }
  }
  return getPlantAgenda(schedule)
}
