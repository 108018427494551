import {validateInterval} from "@vimana/lib-timeline"

import {shapeOf, optional, validateString, validateObject} from "@vimana/vimana-types"

/**
 * An event at a plant.
 */
export const ICalendarEvent = {
  id: undefined,
  sourceId: undefined,
  type: '',
  payload: {},
  interval: {}
}

/**
 * Validate the UUID for an event.
 */
export function validateCalendarEventID(input) {
  return validateString(input)
}

/**
 * Validate the UUID for an event source.
 */
export function validateCalendarEventSourceID(input) {
  return validateString(input)
}

/**
 * Validate an event type.
 */
export function validateCalendarEventType(input) {
  return validateString(input)
}

/**
 * Validate an event payload.
 */
export function validateCalendarEventPayload(input) {
  return validateObject(input)
}

/**
 * Validate an CalendarEvent.
 */
export const validateCalendarEvent = shapeOf({
  id: optional(validateCalendarEventID),
  type: validateCalendarEventType,
  sourceId: optional(validateCalendarEventSourceID),
  payload: validateCalendarEventPayload,
  interval: validateInterval
})
