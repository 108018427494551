import {
  shapeOf,
  arrayOf,
  optional,
  validateString,
  validateDeviceUUID
} from "@vimana/vimana-types"
import {compareTimelineItemsByInterval} from "@vimana/lib-timeline"
import {
  validateCalendarEventConfig,
  getEventOccurrencesBetween,
  validateCalendarEventConfigs
} from "./CalendarEventConfig"

/**
 * Agendas determine shift, classification and other event time information for
 * either an entire plant or 1 or or more specific devices.
 */

export function validateAgendaID(input) {
  return validateString(input)
}

/**
 * Validates an Agenda.
 */
export const validateAgenda = input => {
  shapeOf({
    id: optional(validateAgendaID),
    devices: arrayOf(validateDeviceUUID),
    eventConfigs: arrayOf(validateCalendarEventConfig)
  })(input)
  validateCalendarEventConfigs(input.eventConfigs)
  return input
}

/**
 * Return all event occurrences between the given start and end date times for a specific agenda.
 */
export function getAgendaEventOccurrencesBetween(agenda, start, end) {
  const eventConfigs = []
  for (const config of agenda.eventConfigs) {
    eventConfigs.push(...getEventOccurrencesBetween(config, start, end))
  }
  return eventConfigs.sort(compareTimelineItemsByInterval)
}
