import {shapeOf, arrayOf, validateString} from "@vimana/vimana-types"
import {validateInterval} from "@vimana/lib-timeline"
import {validateCalendarEvent} from "./CalendarEvent"

/**
 * Represents a working day at a plant.
 * Workdays consist of one or more events.
 */
export const validateWorkdayID = validateString

/**
 * Validate a Workday.
 */
export const validateWorkday = shapeOf({
  id: validateWorkdayID,
  interval: validateInterval,
  events: arrayOf(validateCalendarEvent)
})
