import {arrayOf} from "@vimana/vimana-types"
import {validateTimelineItem} from "../TimelineItem"

/**
 * A list of timeline items ordered by time.
 */

/**
 * Validate a timeline.
 */
export const validateTimeline = arrayOf(validateTimelineItem)
