import {DateTime, Duration} from "luxon"
import {compareTimelineItemsByInterval, createOverlappingPredicate} from "@vimana/lib-timeline"
import {shapeOf, arrayOf} from "@vimana/vimana-types"
import {validateTimezone} from "./Timezone"
import {validateSchedule, getDeviceAgenda, getPlantAgenda} from "./Schedule"
import {validateCalendarEvent} from "./CalendarEvent"
import {getAgendaEventOccurrencesBetween} from "./Agenda"
import {getContainingIntervalForWorkday} from "./utils/getContainingIntervalForWorkday"


/**
 * Validate a calendar configuration.
 */
export const validateCalendar = shapeOf({
  timezone: validateTimezone,
  schedules: arrayOf(validateSchedule),
  events: arrayOf(validateCalendarEvent)
})

/**
 * In Vimana, a calendar is a combination of schedules and one-off events (events)
 * that together provide information about the Workday, Shift and
 * Classification for a Plant or one or more Devices at a given point in time.
 *
 * @example
 *
 * const calendar = new Calendar({
 *   timezone: mockTimezone(),
 *   schedules: [mockSchedule(), mockSchedule(), mockSchedule()],
 *   events: [mockCalendarEvent(), mockCalendarEvent())]
 * });
 *
 * console.log(calendar.getWorkdayAt(Date.now()));
 *
 */
export class Calendar {

  constructor(config) {
    this.timezone = config.timezone
    this.schedules = config.schedules
    this.events = config.events
  }

  /**
   * Get the current time at the Plant.
   */
  getPlantTime(timestamp = Date.now()) {
    const {timezone} = this
    return DateTime.fromMillis(timestamp, {zone: timezone.name})
  }

  /**
   * Get a sliding interval based on the given timestamp (defaults to now).
   *
   * @example
   * console.log(calendar.getSlidingInterval('PT1H'));
   * console.log(calendar.getSlidingInterval('PT1H', Date.now()));
   */
  getSlidingInterval(duration, endMillis = Date.now()) {
    const startMillis = this.getPlantTime(endMillis).minus(Duration.fromISO(duration)).valueOf()
    return {startMillis, endMillis}
  }

  /**
   * Get a tumbling interval based on the given timestamp (defaults to now).
   *
   * @example
   * console.log(calendar.getTumblingInterval('PT1H'));
   * console.log(calendar.getTumblingInterval('PT1H', Date.now()));
   */
  getTumblingInterval(duration, timestamp = Date.now()) {
    const milliseconds = Duration.fromISO(duration).as("milliseconds")

    const position = timestamp % milliseconds

    const startMillis = timestamp - position
    const endMillis = startMillis + milliseconds

    return {startMillis, endMillis}
  }

  /**
   * Get the schedules that overlap a given interval.
   */
  getSchedulesOverlapping(interval) {
    return this.schedules.filter(createOverlappingPredicate(interval))
  }

  /**
   * Get the schedules that apply to a given timestamp.
   */
  getSchedulesAt(timestamp) {
    return this.getSchedulesOverlapping({
      startMillis: timestamp,
      endMillis: timestamp
    })
  }

  /**
   * Get the first schedule that applies to a given timestamp.
   * If no schedule matches then `undefined` will be returned.
   */
  getScheduleAt(timestamp, deviceUUID) {
    const schedules = this.getSchedulesAt(timestamp)
    if (schedules.length > 0) {
      if (deviceUUID) {
        for (const schedule of schedules) {
          for (const agenda of schedule.agendas) {
            if (agenda.devices.includes(deviceUUID)) {
              return schedule
            }
          }
        }
      }
      return schedules[0]
    }
  }

  /**
   * Get the singleton events that overlap a given interval.
   */
  getSingletonsOverlapping(interval) {
    return this.events.filter(createOverlappingPredicate(interval))
  }

  /**
   * Get the singleton events that apply to a given timestamp.
   */
  getSingletonsAt(timestamp) {
    return this.getSingletonsOverlapping({
      startMillis: timestamp,
      endMillis: timestamp
    })
  }

  /**
   * Get the first singleton event that applies to a given timestamp.
   * If no singleton matches then `undefined` will be returned.
   */
  getSingletonAt(timestamp) {
    const events = this.getSingletonsAt(timestamp)
    if (events.length > 0) {
      return events[0]
    }
  }

  /**
   * Find the events that overlap the given interval.
   */
  getEventsOverlapping(
    interval,
    query = {}
  ) {
    const events = []
    if (query.deviceUUID == null && query.agendaId == null) {
      events.push(...this.getSingletonsOverlapping(interval))
    }
    const findAgenda = item => item.id === query.agendaId
    for (const schedule of this.getSchedulesOverlapping(interval)) {
      const start = this.getPlantTime(Math.max(interval.startMillis, schedule.interval.startMillis))
      const end = this.getPlantTime(Math.min(interval.endMillis, schedule.interval.endMillis))
      let agenda
      if (query.agendaId) {
        agenda = schedule.agendas.find(findAgenda)
      } else if (query.deviceUUID) {
        agenda = getDeviceAgenda(schedule, query.deviceUUID)
      } else {
        agenda = getPlantAgenda(schedule)
      }

      if (agenda) {
        events.push(...getAgendaEventOccurrencesBetween(agenda, start, end))
      }
    }
    if (query.type) {
      return events.filter(item => item.type === query.type).sort(compareTimelineItemsByInterval)
    }
    return events.sort(compareTimelineItemsByInterval)
  }

  /**
   * Get the corresponding event for a given timestamp.
   */
  getEventAt(timestamp = Date.now(), {type, deviceUUID} = {}) {
    const schedule = this.getScheduleAt(timestamp, deviceUUID)
    if (!schedule) {
      // If there's no schedule there can be no workday.
      return
    }

    const events = this.getEventsOverlapping({startMillis: timestamp, endMillis: timestamp}, {deviceUUID})

    let candidate
    for (const event of events) {
      if ((type == null && event.type !== "Holiday") || event.type === type) {
        candidate = event
      }
    }

    return candidate
  }

  /**
   * Find an event config based on its id.
   */
  getEventConfigByID(id) {
    for (const schedule of this.schedules) {
      for (const agenda of schedule.agendas) {
        for (const event of agenda.eventConfigs) {
          if (event.id === id) {
            return event
          }
        }
      }
    }
  }

  /**
   * Find an agenda based on its id.
   */
  getAgendaByID(id) {
    for (const schedule of this.schedules) {
      for (const agenda of schedule.agendas) {
        if (agenda.id === id) {
          return agenda
        }
      }
    }
  }

  /**
   * Given an event config id, return the agenda which contains that event.
   */
  getAgendaByEventConfigID(id) {
    for (const schedule of this.schedules) {
      for (const agenda of schedule.agendas) {
        for (const event of agenda.eventConfigs) {
          if (event.id === id) {
            return agenda
          }
        }
      }
    }
  }

  /**
   * Find the schedule for an agenda based on the agenda's id.
   */
  getScheduleByAgendaID(id) {
    for (const schedule of this.schedules) {
      for (const agenda of schedule.agendas) {
        if (agenda.id === id) {
          return schedule
        }
      }
    }
  }

  /**
   * Get the workday at a particular timestamp, optionally for a specific device.
   * Returns the workday instance or undefined if no workday is scheduled.
   *
   * When a device is specified, if there are *any* events of a type specific
   * to the device then *only* those events apply to the device.
   *
   * If a device has no events then it defaults to the events for the workday.
   */
  getWorkdayAt(timestamp, deviceUUID) {
    const schedule = this.getScheduleAt(timestamp, deviceUUID)
    if (!schedule) {
      // If there's no schedule there can be no workday.
      return
    }
    const adjustedPlantTime = this.getPlantTime(timestamp - schedule.workdayOffset)

    const interval = {
      startMillis: adjustedPlantTime.startOf("day").valueOf() + schedule.workdayOffset,
      endMillis: adjustedPlantTime.startOf("day").plus({days: 1}).valueOf() + schedule.workdayOffset
    }

    const events = this.getEventsOverlapping(interval, {deviceUUID})
    const id = adjustedPlantTime.toISODate()

    return {id, interval, events}
  }

  /**
   *
   * Get the workday at a particular workdayId, optionally for a specific device.
   * Check the schedule defined at the left or right edge of a workday using the considerRightEdge option.
   * Returns the workday instance or undefined if no workday is scheduled.
   *
   * When a device is specified, if there are *any* events of a type specific
   * to the device then *only* those events apply to the device.
   *
   * If a device has no events then it defaults to the events for the workday.
   *
   * @param workdayId
   * @param considerRightEdge
   * @param deviceUUID
   * @returns {?IWorkday}
   */
  getWorkdayForDay(workdayId, considerRightEdge = false, deviceUUID) {
    const workdayDateTime = considerRightEdge
      ? // using end of workday
        DateTime.fromISO(workdayId, {zone: "UTC"}).set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999
        })
      : // using start of workday
        DateTime.fromISO(workdayId, {zone: "UTC"})
    const prevDay = workdayDateTime.startOf("day").minus({hours: 24}).valueOf()
    const currentDay = workdayDateTime.startOf("day").valueOf()
    const nextDay = workdayDateTime.startOf("day").plus({hours: 24}).valueOf()
    const workdayForPrevDay = this.getWorkdayAt(prevDay, deviceUUID)
    const workdayForCurrentDay = this.getWorkdayAt(currentDay, deviceUUID)
    const workdayForNextDay = this.getWorkdayAt(nextDay, deviceUUID)
    if (workdayForPrevDay && workdayForPrevDay.id === workdayId) {
      return workdayForPrevDay
    }
    if (workdayForCurrentDay && workdayForCurrentDay.id === workdayId) {
      return workdayForCurrentDay
    }

    if (workdayForNextDay && workdayForNextDay.id === workdayId) {
      return workdayForNextDay
    }

    /**
     * schedule has changed and the current workday lies between the current day and the next workday fired by the
     * new schedule. So the start of workday is the end of the previous workday.
     * The end of workday is either the start of the next workday or start of the next schedule whichever is later
     */

    const nextSchedule = this.getScheduleAt(nextDay)
    if (!workdayForCurrentDay || !workdayForNextDay || !nextSchedule) {
      return null
    }
    const intervalStart = workdayForCurrentDay.interval.endMillis
    const intervalEnd =
      workdayForNextDay.interval.startMillis < nextSchedule.interval.startMillis
        ? nextSchedule.interval.startMillis
        : workdayForNextDay.interval.startMillis
    const interval = {
      startMillis: intervalStart,
      endMillis: intervalEnd
    }

    const events = this.getEventsOverlapping(interval, {deviceUUID})
    return {id: workdayId, interval, events}
  }

  getIntervalForWorkdays(workdays, deviceUUID) {
    let interval = {
      startMillis: 0,
      endMillis: 0
    }
    const numberOfWorkdays = workdays.length
    const lastIndex = numberOfWorkdays - 1
    // When there is only 1 workday, we need to consider both left and right edges of that workday
    const workdayList = numberOfWorkdays === 1 ? [workdays[0], workdays[0]] : workdays
    const _workdays = workdayList.map((workday, index) =>
      // the last workday should consider the schedule at it's right edge to use correct schedule at end of workday
      index === lastIndex
        ? this.getWorkdayForDay(workday, true, deviceUUID)
        : this.getWorkdayForDay(workday, false, deviceUUID)
    )
    // get the union of all intervals
    _workdays.forEach(workday => {
      interval = getContainingIntervalForWorkday(workday, interval)
    })

    return interval
  }

  toJSON() {
    return {
      timezone: this.timezone,
      schedules: this.schedules,
      events: this.events
    }
  }
}
