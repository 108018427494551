// @flow
import {isIntervalOverlapping} from "../Interval"


/**
 * Create a predicate which returns true for timeline items with intervals
 * overlapping the given interval(s).
 *
 * @example
 * const timeline = mockTimeline();
 * const predicate = createOverlappingPredicate(mockInterval());
 * console.log(timeline.filter(predicate));
 */
export function createOverlappingPredicate(...intervals) {
  return (item) => intervals.some(interval => isIntervalOverlapping(interval, item.interval))
}
