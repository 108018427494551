import {validateString, validateNumber, shapeOf, invariant} from "@vimana/vimana-types"
import {DateTime} from "luxon"

/**
 * Represents the timezone of a Plant.
 */

/**
 * Validates a timezone name.
 */
export const validateTimezoneName = (input) => {
  validateString(input)
  invariant(DateTime.local().setZone(input).isValid, "Must be a valid timezone name.")
  return input
}

/**
 * Validates a timezone offset.
 */
export const validateTimezoneUTCOffset = (input) => {
  return validateNumber(input)
}

/**
 * Validates a timezone.
 */
export const validateTimezone = shapeOf({
  name: validateTimezoneName,
  currentOffsetMillis: validateTimezoneUTCOffset
})

/**
 * Get the UTC offset for a timezone with the given name and time.
 */
export function getTimezoneOffsetMillis(name, timeInUTC = new Date().getTime()) {
  return DateTime.fromSeconds(timeInUTC / 1000).setZone(name).offset * 60000
}
