import {compareIntervals} from "../Interval"

/**
 * Compare two timeline items by interval. Used when sorting.
 *
 * @example
 * const items = [
 *   mockTimelineItem(),
 *   mockTimelineItem(),
 *   mockTimelineItem()
 * ].sort(compareTimelineItemsByInterval);
 *
 * console.log(items); // Now in order.
 */
export function compareTimelineItemsByInterval(a, b) {
  return compareIntervals(a.interval, b.interval)
}
