import {validateUnixTimeMS, validateObject, invariant} from "@vimana/vimana-types"

/**
 * A time range expressed in milliseconds since the unix epoch.
 */

/**
 * Validate a time interval.
 */
export function validateInterval(input) {
  validateObject(input)
  validateUnixTimeMS(input.startMillis)
  validateUnixTimeMS(input.endMillis)
  invariant(input.endMillis >= input.startMillis, "Interval must end after the start.")
  return input
}
