/**
 * Compare two interval objects, for use when sorting.
 *
 * @example
 * const intervals = [mockInterval(), mockInterval(), mockInterval()].sort(compareIntervals);
 * console.log(intervals); // now in order
 */
export function compareIntervals(a, b) {
  if (a.startMillis > b.startMillis) {
    return 1
  }
  if (a.startMillis < b.startMillis) {
    return -1
  }
  if (a.endMillis > b.endMillis) {
    return 1
  }
  if (a.endMillis < b.endMillis) {
    return -1
  }
  return 0
}
